import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormStatus } from '../../utils/formStatus';
import { HttpErrorResponse } from '@angular/common/http';
import { extractErrorResponse } from '../../utils/extractErrorResponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { appConfigs } from '../../constants/initialize';
import { take } from 'rxjs/operators';
import { General } from '../../models/general.model';
import { ITermsConditions, TermsConditions } from '../../models/terms-conditions.model';
import { TermsConditionsService } from '../../services/terms-conditions.service';
// import { Editor, Toolbar } from 'ngx-editor';
import { IPolicyPrivacy, PolicyPrivacy } from '../../models/policy-privacy.model';

@Component({
    selector: 'app-privacy-policies',
    templateUrl: 'privacy-policy.component.html',
})
export class PrivacyPolicyComponent implements OnInit {
   public policyPrivacy: PolicyPrivacy;

    constructor(private termsConditionsService: TermsConditionsService, protected ngxService: NgxUiLoaderService) {
    }

    ngOnInit(): void {
        this.searchInfo();
    }
    searchInfo() {
        this.ngxService.start();
        this.termsConditionsService.lastTermsConditions().subscribe(
            async (response) => {
                this.policyPrivacy = response.body.data;
                this.ngxService.stop();
            }, (errorResponse: HttpErrorResponse) => {
                Swal.fire(extractErrorResponse(errorResponse, true));
                this.ngxService.stop();
            }
        );
    }
}
