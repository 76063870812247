import { Component } from '@angular/core';
import {appConfigs} from '../../constants/initialize';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  currentDate: Date = new Date();
  teamQWebsiteUrl = appConfigs.teamQWebsiteUrl;
}
