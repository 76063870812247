import { HttpErrorResponse } from '@angular/common/http';

export const extractErrorResponse = (errorResponse: HttpErrorResponse, onlyFirstError: boolean) => {
    const errors = [];
    if (errorResponse.error) {
        if (errorResponse.error.data?.content) {
            if (errorResponse.error.data.content instanceof Array) {
                errorResponse.error.data.content.forEach(error => errors.push(error));
            } else {
                errors.push(errorResponse.error.data.content);
            }
        } else {
            errors.push(errorResponse.error.message);
            if (errorResponse.error.errors) {
                for (const property in errorResponse.error.errors) {
                    if (errorResponse.error.errors.hasOwnProperty(property)) {
                        const propertyErrors: Array<string> = errorResponse.error.errors[property];
                        propertyErrors.forEach(error => errors.push(error));
                    }
                }
            }
        }
    }
    return onlyFirstError ? errors[0] : errors;
};
