export const OPTIONS = [
    {
        path: '/home',
        title: 'dashboard',
        type: 'link',
        icontype: 'fas fa-home fa-2x',
        abilities: ['index-home'],
    },
    {
        path: '/admin/terms-conditions',
        title: 'termsConditions',
        type: 'link',
        icontype: 'fas fa-file fa-2x',
        abilities: ['index-terms-conditions'],
    },
    {
        path: '',
        title: 'users',
        type: 'father',
        icontype: 'fas fa-users fa-2x',
        isCollapsed: true,
        abilities: ['index-clients', 'index-providers', 'index-managers'],
        children: [
            {
                path: '/admin/clients',
                title: 'clients',
                type: 'sub',
                icontype: 'fas fa-user fa-2x',
                abilities: ['index-clients'],
            },
            {
                path: '/admin/providers',
                title: 'providers',
                type: 'sub',
                icontype: 'fas fa-user fa-2x',
                abilities: ['index-providers'],
            },
            {
                path: '/admin/managers',
                title: 'managers',
                type: 'sub',
                icontype: 'fas fa-user fa-2x',
                abilities: ['index-managers'],
            },
        ],
    },
    {
        path: '',
        title: 'catalogs',
        type: 'father',
        icontype: 'fas fa-cubes fa-2x',
        isCollapsed: true,
        abilities: [ 'index-business', 'index-categories', 'index-categories-ep', 'index-subcategories', 'index-services', 'index-events', 'index-promotions'],
        children: [
            {
                path: '/admin/business',
                title: 'businessMenu',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-business'],
            },
            {
                path: '/admin/categories',
                title: 'categories',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-categories'],
            },
            {
                path: '/admin/categories-event-promo',
                title: 'categoriesEP',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-categories-ep'],
            },
            {
                path: '/admin/subcategories',
                title: 'subcategories',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-subcategories'],
            },
            {
                path: '/admin/sub-subcategories',
                title: 'sub-subcategories',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-subcategories'],
            },
            {
                path: '/admin/services',
                title: 'services',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-services'],
            },
            {
                path: '/admin/events',
                title: 'events',
                type: 'sub',
                icontype: 'fas fa-bell fa-2x',
                abilities: ['index-events'],
            },
            {
                path: '/admin/promotions',
                title: 'promotions',
                type: 'sub',
                icontype: 'fas fa-cube fa-2x',
                abilities: ['index-promotions'],
            },
        ],
    },
];
