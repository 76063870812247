import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../../models/users.model';
import { RouteInfo } from '../../models/sidebar';
import { OPTIONS } from '../../constants/menu';
import { appConfigs } from '../../constants/initialize';

export const ROUTES: RouteInfo[] = OPTIONS;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
    @Output() logout = new EventEmitter();
    @Output() minifiedSidebar = new EventEmitter();
    myAbilities: string[];
    menuItems: RouteInfo[];
    isCollapsed = true;
    user: User;
    ngOnInit() {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.myAbilities = this.user.my_permission;
        }
        this.menuItems = ROUTES.filter((menuItem) => menuItem);
    }

    optCollapsing(event, menuitem) {
        event.preventDefault();
        event.stopPropagation();
        if (menuitem !== undefined && menuitem.isCollapsing === undefined) {
            menuitem.isCollapsing = true;
        }
    }

    isCollapsing(event, menuitem) {
        event.preventDefault();
        event.stopPropagation();
        return menuitem.isCollapsed = !menuitem.isCollapsed;
    }

    isFather(menuitem: RouteInfo) {
        return menuitem.type === appConfigs.typeFather;
    }
    checkAbilitiesInRol(abilities: string[]): boolean {
         let exists = false;
         abilities.forEach((ability) => {
            exists = true;
            //  if (this.myAbilities.includes(ability)) {
            //      exists = true;
            //  }
         });
         return exists;
    }
    haveAbilityWithoutFather(menuitem: RouteInfo, abilities: string[]): boolean {
        return !this.isFather(menuitem) && this.checkAbilitiesInRol(abilities);
    }
    haveAbilityWithFather(menuitem: RouteInfo, abilities: string[]): boolean {
        return this.isFather(menuitem) && this.checkAbilitiesInRol(abilities);
    }
}
