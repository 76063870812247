<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute bg-primary">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <a class="navbar-brand" [routerLink]="['/home']">
                <img alt="{{getTitle()}}" class="img-logo" src="./assets/img/logoapp.png"/>
            </a>
        </div>
        <div class="collapse navbar-collapse justify-content-end"
             id="collapseExample"
             [ngbCollapse]="!isCollapsed">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link text-white" [routerLink]="['/home']">
                        <i class="now-ui-icons design_app"></i>
                        {{ 'dashboard' | translate }}
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link text-white" [routerLink]="['/pages/lock']">
                        <i class="now-ui-icons ui-1_lock-circle-open"></i>
                        {{ 'recoveryUser' | translate }}
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link text-white" [routerLink]="['/pages/notCompleted']">
                        <i class="now-ui-icons ui-1_lock-circle-open"></i>
                        {{ 'btnCompleteRegistration' | translate }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="wrapper wrapper-full-page">
    <div class="full-page section-image login-page">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <div class="full-page-background full-page-background-aut"></div>
    </div>
</div>
