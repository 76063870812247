export const appServiceURL = {
    listsSubscription: '/select/subscriptions',
    listsProviders: '/select/providers',
    listsCategories: '/select/categories/children',
    listsCatalogs: '/select/catalogs',
    listsRoles: '/select/roles',
    listsTagServices: '/list/tagServices',
    listsTagGenerals: '/list/tagGeneral',
    moduleUsers: '/users',
    moduleCatalogs: '/catalogs',
    moduleCategories: '/categories',
    moduleTermsConditions: '/termConditions',
    termsConditions: '/list/term-conditions',
    moduleSubCategories: '/subcategories',
    moduleSubSubCategories: '/subSubCategory',
    listsCommentsCatalogs: '/list/catalogs/{id}/comments',
    moduleReviews: '/comments',
    moduleEvents: '/events',
    modulePromotions: '/promotions',
    moduleBusiness: '/business',
    moduleCategoriesEP: '/eventPromotionCategory',
};
