<div class="d-flex align-items-center justify-content-center vh-100">
  <div class="text-center">
    <img src="./assets/img/logoapp.png" alt="{{'notFoundCode' | translate}}">
    <h1 class="display-1 fw-bold pt-20">{{'notFoundCode' | translate}}</h1>
    <p class="fs-3"> <span class="text-danger">{{'Opps' | translate}}</span> {{'notFound' | translate }}</p>
    <p class="lead">
      {{'notFoundDetail' | translate }}
    </p>
    <a href="/" class="btn btn-primary">{{'returnSite' | translate}}</a>
  </div>
</div>
