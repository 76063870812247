<footer class="footer">
  <div class="container-fluid">
    <nav>
      <ul>
      
      </ul>
    </nav>
    <div class="copyright" id="copyright">
      &copy; {{ currentDate | date: "yyyy" }}, {{'designBy' | translate}}
      <a href="{{teamQWebsiteUrl}}" target="_blank">{{'teamQLabel' | translate}}</a>
    </div>
  </div>
</footer>
